<template>
  <v-container class="home mainBg">
    <h1>Dashboard</h1>
    <v-row>
      <v-col cols="12">
        <div class="d-flex flex-wrap mb-6">
          <v-card class="d-inline-flex ma-2" 
            width="235"
            flat
            color="transparent">
              <h1 class="mr-5 display-4 textDateColorDashboard">{{ dateNow.date }}</h1>
              <div class="d-flex align-self-center flex-column mb-6">
                <div class="display-1 textDateColorDashboard">{{ dateNow.month }}</div>
                <div class="display-1 textDateColorDashboard">{{ dateNow.year }}</div>
              </div>
          </v-card>
          <v-card class="ma-2 smallBoxDashboard">
            <v-card-subtitle class="overline">Jobs</v-card-subtitle>
            <v-card-text class="display-1 text-right">{{ dataDashboard.jobs }}</v-card-text>
          </v-card>
          <v-card class="ma-2 smallBoxDashboard">
            <v-card-subtitle class="overline">Pending</v-card-subtitle>
            <v-card-text class="display-1 text-right">{{ dataDashboard.pending }}</v-card-text>
          </v-card>
          <v-card class="ma-2 smallBoxDashboard">
            <v-card-subtitle class="overline">Transection / Failed</v-card-subtitle>
            <v-card-text class="display-1 text-right">{{ transactionsTotal }} / 
              <span class="overline red--text">{{ failsTotal }}</span></v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col cols="6"
        md="6"
        sm="12">
        <v-card class="d-inline-flex flex-row pa-5 boardBigGrap">
          <div class="ma-2">
            <div class="overline white--text">Customers Registration</div>
            <h1 class="textCustomerRegis white--text">{{ registerTotal }}</h1>
            <div class="align-self-end">
              <div class="overline white--text">
                <v-icon color="#6FCF97">mdi-border-all-variant</v-icon> Postpaid = {{ postPaidTotal }}
              </div>
              <div class="overline white--text">
                <v-icon color="#F2C94C">mdi-border-all-variant</v-icon> Prepaid = {{ prePaidTotal }}
              </div>
            </div>
          </div>
          <div class="ml-10">
            <os-chart
              class="chartOS"
              :chartData="osData"
              :options="osChartOption" />
          </div>
        </v-card>
      </v-col>
      <v-col cols="6"
        md="6"
        sm="12">
        <v-card class="d-inline-flex flex-row pa-5 boardBigGrap">
          <div class="ma-2">
            <div class="overline white--text">Platforms Registration</div>
            <h1 class="textCustomerRegis white--text">{{ registerTotal }}</h1>
            <div class="align-self-end">
              <div class="overline white--text">
                <v-icon color="#6FCF97">mdi-border-all-variant</v-icon> DTAC Lite = {{ dtacLiteTotal }}
              </div>
              <div class="overline white--text">
                <v-icon color="#F2C94C">mdi-border-all-variant</v-icon> iOS Application = {{ iosTotal }}
              </div>
              <div class="overline white--text">
                <v-icon color="#F1F8E9">mdi-border-all-variant</v-icon> Android Application = {{ androidTotal }}
              </div>
            </div>
          </div>
          <div>
            <!-- <os-chart
              class="chartOS"
              :chartData="platformData"
              :options="platformOption" /> -->
          </div>
        </v-card>
      </v-col>
      <v-col cols="12"
        md="6">
        <v-card>
          <os-chart
            :chartData="androidVerData"
            :options="androidVerChartOption" />
        </v-card>
      </v-col>
      <v-col cols="12"
        md="6">
        <v-card>
          <os-chart
            :chartData="iosVerData"
            :options="iosVerChartOption" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
// import Chart from 'chart.js';

export default {
  name: 'Dashboard',
  data () {
    return {
      dashboard: {},
      dateNow: {
        date: '',
        month: '',
        year: ''
      },
      registerTotal: 0,
      androidTotal: 0,
      iosTotal: 0,
      dtacLiteTotal: 0,
      postPaidTotal: 0,
      prePaidTotal: 0,
      transactionsTotal: 0,
      failsTotal: 0,
      osData: null,
      osChartOption: null,
      androidVerChartOption: null,
      androidVerData: null,
      iosVerData: null,
      iosVerChartOption: null,
      platformData: null,
      platformOption: null
    }
  },

  components: {
    OsChart: () => import('@/components/dashboard/chartOS.vue'),
    AppChart: () => import('@/components/dashboard/chartPie.vue'),
  },

  async mounted () {
    await this.$store.dispatch('dashData/Get_Dashboards');
    await this.getDate();

    await this.chartOSData();
    await this.chartAppVersion();
    await this.chartPlatformData();
  },

  computed: {
    ...mapGetters({
      dataDashboard: 'dashData/GET_DASHBOARD'
    })
  },

  watch: {
    dataDashboard (val) {
      this.registerTotal = val.registrations.toLocaleString();
      this.dtacLiteTotal = val.web.toLocaleString();
      this.iosTotal = val.iOS.toLocaleString();
      this.androidTotal = val.android.toLocaleString();
      this.postPaidTotal = val.postPaid.toLocaleString();
      this.prePaidTotal = val.prePaid.toLocaleString();
      this.failsTotal = val.fails.toLocaleString();
      this.transactionsTotal = val.transactions.toLocaleString();
    }
  },

  methods: {
    async chartOSData () {
      this.osData = {
        labels: ['Postpaid', 'Prepaid'],
        datasets: [
          {
            data: [this.dataDashboard.postPaid, this.dataDashboard.prePaid],
            backgroundColor: [
              '#6FCF97',
              '#F2C94C'
            ],
            hoverOffset: 4
          },
        ]
      }

      this.osChartOption = {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false
      }
    },

    async chartPlatformData () {
      this.platformData = {
        labels: ['DtacLite', 'iOS', 'Android'],
        datasets: [
          {
            data: [this.dtacLiteTotal, this.iosTotal, this.androidTotal],
            backgroundColor: [
              '#6FCF97',
              '#F2C94C',
              '#F1F8E9'
            ],
            hoverOffset: 4
          },
        ]
      }

      this.platformOption = {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false
      }
    },

    async chartAppVersion () {
      // const total = [];
      const findAndroid = [];
      const findIos = [];
      
      const sortVer = this.dataDashboard.versions.map((a) => {
        return {
          device: a.device,
          version: a.version.split('.'),
          total: a.total
        }
      }).map(a => {
        while (a.version.length < 2) a.version.push('0');

        // eslint-disable-next-line radix
        a.version.map(aa => parseInt(aa));

        return a;
      }).sort((a, b) => {
        if (a.version[0] > b.version[0]) return -1;
        if (a.version[0] < b.version[0]) return 1;

        if (a.version[1] > b.version[1]) return -1;
        if (a.version[1] < b.version[1]) return 1;

        if (a.version[2] > b.version[2]) return -1;
        if (a.version[2] < b.version[2]) return 1;

        return 0;
      }).map(a => {
        return {
          device: a.device,
          version: a.version.join('.'),
          total: a.total
        }
      });

      let otherAndroidTotal = 0;
      let otherIosTotal = 0;

      const filterAndroid = (data) => data.device === 'android';
      const filterIos = (data) => data.device === 'ios';

      const androidApp = sortVer.filter((data) => data.device === 'android');
      const iosApp = sortVer.filter((data) => data.device === 'ios');

      const totalAndroid = androidApp
        .reduce((sum, total) => {
          sum += total.total;
          return sum;
        }, 0);

      const totalIOs = iosApp
        .reduce((sum, total) => {
          sum += total.total;

          return sum
        }, 0);

      androidApp 
        .map((m, i) => {
          if (i < 9) {
            findAndroid.push({
              device: m.device,
              version: m.version,
              totals: `${((m.total / totalAndroid) * 100).toFixed(2)  }`
            });
          } else {
            otherAndroidTotal += m.total;
            
            findAndroid.push({
              device: m.device,
              version: 'Other',
              totals: `${((otherAndroidTotal / totalAndroid) * 100).toFixed(2)  }`
            });
          }

          return findAndroid
        });

      iosApp
        .map((m, i) => {
          if (i < 9) {
            findIos.push({
              device: m.device,
              version: m.version,
              totals: `${((m.total / totalIOs) * 100).toFixed(2)  }`
            });
          } else {
            otherIosTotal += m.total
            findIos.push({
              device: m.device,
              version: 'Other',
              totals: `${((otherIosTotal / totalIOs) * 100).toFixed(2)  }`
            });
          }

          return findIos
        });

      const resultAndroid = findAndroid
            .filter(filterAndroid)
            .reduce((state, data) => {
              if (state[data.version]) {
                state[data.version] += data.totals
              } else {
                state[data.version] = data.totals
              }

              return state
            }, {});

      const resultIOS = findIos
            .filter(filterIos)
            .reduce((state, data) => {
              if (state[data.version]) {
                state[data.version] += data.totals
              } else {
                state[data.version] = data.totals
              }

              return state
            }, {});

      this.androidVerData = {
        labels: Object.keys(resultAndroid),
        datasets: [
          {
            data: Object.values(resultAndroid),
            backgroundColor: ['#FFCDD2', '#F8BBD0', '#E1BEE7', '#D1C4E9', '#BBDEFB', '#B2DFDB', '#DCEDC8', '#FFE0B2', '#FFCCBC', '#D7CCC8', '#CFD8DC'],
            hoverOffset: 4
          }
        ]
      };

      this.androidVerChartOption = {
        legend: {
          display: true
        },
        title: {
          display: true,
          text: `DEVICES ANDROID: ${this.androidTotal}`,
        },
        tooltips: {
          callbacks: {
            title(tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            },
            label(tooltipItem, data) {
              const dataset = data.datasets[0];
              // const percent = ((dataset.data[tooltipItem.index] / totalAndroid) * 100).toFixed(2);
              // console.log(dataset.data[tooltipItem.index])
              return ` ${  dataset.data[tooltipItem.index]  } %`;
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      };

      this.iosVerData = {
        labels: Object.keys(resultIOS),
        datasets: [
          {
            data: Object.values(resultIOS),
            backgroundColor: ['#FFCDD2', '#E1BEE7', '#D1C4E9', '#BBDEFB', '#B2DFDB', '#DCEDC8', '#FFE0B2', '#D7CCC8', '#CFD8DC'],
            hoverOffset: 4
          },
        ]
      };

      this.iosVerChartOption = {
        legend: {
          display: true
        },
        title: {
          display: true,
          text: `DEVICES IOS: ${this.iosTotal}`,
        },
        tooltips: {
          callbacks: {
            title(tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            },
            label(tooltipItem, data) {
              const dataset = data.datasets[0];
              // const percent = ((dataset.data[tooltipItem.index] / totalIOs) * 100).toFixed(2);
              return ` ${  dataset.data[tooltipItem.index]  } %`;
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      };
    },

    async getDate () {
      const today = new Date;
      this.dateNow.date = today.getDate();
      
      switch (today.getMonth()) {
        case 0:
            this.dateNow.month = 'January'
          break;
      
        case 1:
            this.dateNow.month = 'February'
          break;

        case 2:
            this.dateNow.month = 'March'
          break;

        case 3:
            this.dateNow.month = 'April'
          break;

        case 4:
            this.dateNow.month = 'May'
          break;

        case 5:
            this.dateNow.month = 'June'
          break;

        case 6:
            this.dateNow.month = 'July'
          break;

        case 7:
            this.dateNow.month = 'August'
          break;

        case 8:
            this.dateNow.month = 'September'
          break;

        case 9:
            this.dateNow.month = 'October'
          break;

        case 10:
            this.dateNow.month = 'November'
          break;

        case 11:
            this.dateNow.month = 'December'
          break;
        default:
          break;
      }

      this.dateNow.year = today.getFullYear();
    }
  }
};
</script>

<style scoped>
  .chartOS {
    position: absolute;
    top: 5px;
    height: 240px;
    width: 240px;
  }
</style>
